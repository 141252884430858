<template>
  <div class="club">
    <module-header title="美迪会" icon="icon-club" @click="toClub"></module-header>
    <div class="content">
      <div class="title">&gt;&gt;MIMS发布</div>
      <div class="item line2" v-for="(item, index) of list" :key="'club' + index">
        <a :href="`${$constants.routers.newsDetail.link}?id=${item.Id}&type=meidihui`">
          #{{ item.Title }}#{{ item.SubTitle }}
        </a>
      </div>
      <div class="title">&gt;&gt;会员投稿精选</div>
      <div class="vip">
        <div v-for="(item, index) of list2" :key="'club2' + index" class="item">
          <a :href="`${$constants.routers.newsDetail.link}?id=${item.Id}&type=meidihui`">{{ item.Title }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleHeader from '../common/ArtHeaderLeft';
export default {
  name: 'MimsClub',
  components: { ModuleHeader },
  data() {
    return {
      list: [],
      list2: [],
    };
  },
  created() {
    this.$apis.club.getMeidihui({ Category: 1, CategoryId: 0, CategoryType: 3 }).then(res => {
      this.list = res.data;
    });
    this.$apis.club.getMeidihui({ Category: 2, CategoryId: 0, CategoryType: 3 }).then(res => {
      this.list2 = res.data;
    });
  },
  methods: {
    toClub() {
      location.assign('club.html');
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables.scss';
.club {
  padding-bottom: 60px;
  .content {
    /*padding: 20px 0;*/
    .title {
      font-size: 20px;
      font-weight: bold;
      padding: 30px 0 10px;
      color: $font-main;
    }
    .item {
      margin: 16px 0;
      color: $font-sec;
      font-size: 14px;
      .tag {
        color: $font-main;
        font-weight: bold;
        padding-right: 10px;
      }
    }
  }
}
.vip {
  .item {
    padding: 0 26px;
    border-left: 2px solid $theme-red;
    line-height: 1.2;
  }
}
</style>
