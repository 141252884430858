<template>
  <div class="quick-entry">
    <div class="item" v-for="(item, index) of list" :key="index">
      <el-popover
        v-if="item.hoverImg"
        placement="top"
        width="200"
        trigger="hover">
        <div class="tc">
          <img :src="item.hoverImg" alt="微信小程序" width="100%">
        </div>
        <a slot="reference" href="javascript:void(0)">
          <div class="pic">
            <img :src="item.img" />
          </div>
          <h1 class="title">{{ item.title }}</h1>
          <p class="content" v-html="item.content"></p>
        </a>
        <!--<el-button slot="reference">click 激活</el-button>-->
      </el-popover>
      <a v-else :href="item.link">
        <div class="pic">
          <img :src="item.img" />
        </div>
        <h1 class="title">{{ item.title }}</h1>
        <p class="content" v-html="item.content"></p>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuickEntry',
  data() {
    return {
      list: [
        {
          title: '由药品信息获知药物详情',
          content: '浏览药监局批准的详细药物<br/>处方信息',
          img: require('../../assets/image/quick-entry01.png'),
          link: this.$constants.routers.tradeName.link,
        },
        {
          title: '从疾病指南中了解病征',
          content: '方便医生快速查询疾病诊断和<br/>治疗信息',
          img: require('../../assets/image/quick-entry02.png'),
          link: this.$constants.routers.diseaseName.link,
        },
        {
          title: '从原版文献中获得知识',
          content: '获悉趋时的科研成果及国内外<br/>疾病进展',
          img: require('../../assets/image/quick-entry03.png'),
          link: this.$constants.routers.authority.link,
        },
        {
          title: '使用AI为诊疗提供便捷',
          content: '模拟诊疗环境协助医生问诊的<br/>得力助手',
          img: require('../../assets/image/quick-entry04.png'),
          link: this.$constants.routers.index.link,
          hoverImg: require('../../assets/image/wexin_miniprogram.jpg')
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables.scss';
.quick-entry {
  display: flex;
  padding: 25px 0 33px 0;
  .item {
    flex: 1;
    text-align: center;
    a {
      display: inline-block;
    }
    .pic {
      width: 85px;
      height: 85px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .title {
      font-size: 15px;
      line-height: 1.5;
      color: $font-main;
    }
    .content {
      font-size: 12px;
      color: $font-sec;
    }
  }
}
</style>
