<template>
	<Layout :adShopMallAreaId="5">
		<div class="mv6">
			<div class="home-row">
				<div class="home-left">
					<div class="main-banner">
						<el-carousel trigger="click" height="100%">
							<el-carousel-item v-for="(item, index) in banner" :key="index">
								<a class="rel" :href="item.TargetUrl" target="_blank">
									<img :src="item.ImagePath" class="full" />
									<div class="banner-title">{{ item.Description }}</div>
								</a>
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
				<NewProdFocus class="home-right"></NewProdFocus>
			</div>
			<quick-entry></quick-entry>
			<sec-nav></sec-nav>
			<long-banner :ad="adMap[4]" />

			<!-- 侃侃病例 -->
			<!-- 			<div id="cases" class="home-row">
				<DiseaseList class="home-left" :params="listParams"></DiseaseList>
				<div class="home-right">
					<ProdFocusText></ProdFocusText>
					<AdRight :ad="adMap[1]"></AdRight>
				</div>
			</div> -->
			<!--热点资讯-->
			<div id="news" class="home-row">
				<NewsList class="home-left" :params="listParams"></NewsList>
				<LatestHot class="home-right mt1" :params="hotParams"></LatestHot>
			</div>
			<!--培训课堂-->
			<div id="train" class="home-row">
				<TrainingClass class="home-left" :params="listParams" />
				<TopHits class="home-right" :params="hotParams"></TopHits>
			</div>

			<!--会议资讯-->
			<div id="meeting" class="home-row">
				<MeetingList class="home-left" :params="listParams"></MeetingList>
				<div class="home-right">
					<AdMeeting :ad="adMap[3]" />
					<LatestMeeting :params="hotParams" />
				</div>
			</div>

			<div id="mims" class="home-row">
				<MimsClub class="home-left"></MimsClub>
				<div class="home-right">
					<MemberShow></MemberShow>
					<!-- <AdSignIn :ad="adMap[2]" /> -->
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import Layout from '@/layout/index';
	import NewProdFocus from '../../components/newProdFocus/Carousel';
	import QuickEntry from '../../components/home/QuickEntry';
	import LongBanner from '../../components/ad/long';
	import SecNav from '../../components/home/SecNav';
	// import AdRight from '../../components/ad/long-v';
	import LatestMeeting from '../../components/meeting/Latest';
	import LatestHot from '../../components/hot/Latest';
	// import ProdFocusText from '../../components/common/prodFocus';
	import TrainingClass from '../../components/TrainingClass';
	import TopHits from '../../components/TopHits';
	import MimsClub from '../../components/home/MimsClub';
	import MemberShow from '../../components/home/MemberShow';
	import MeetingList from '@/components/meeting-list';
	import NewsList from '@/components/news-list';
	// import DiseaseList from '@/components/disease-list';
	// import AdSignIn from '@/components/ad/signin';
	import AdMeeting from '@/components/ad/meeting';
	import {
		mapActions,
		mapState
	} from 'vuex';

	export default {
		metaInfo: {
			title: '查询药物信息、相互作用、图片、用法用量和副作用 | 敏思网页 中文版', // set a title
			meta: [{ // set meta
				name: 'keyWords',
				content: '药品信息、药物辨识图、药物相互作用、临床诊断、决策支持系统、用法用量、副作用、治疗指南'
			}, {
				name: 'description',
				content: '在中国、香港、台湾、马来西亚、新加坡、菲律宾、越南、泰国、印尼、印度和美国查询药物信息、药物相互作用、药物图片'
			}]
		},
		components: {
			Layout,
			MemberShow,
			MimsClub,
			NewProdFocus,
			QuickEntry,
			SecNav,
			// AdRight,
			// AdSignIn,
			// ProdFocusText,
			LongBanner,
			NewsList,
			LatestMeeting,
			LatestHot,
			TrainingClass,
			TopHits,
			MeetingList,
			// DiseaseList,
			AdMeeting,
		},
		data() {
			return {
				banner: [],
				listParams: {
					CategoryType: 3,
					CategoryId: 0,
				},
				hotParams: {
					CategoryType: 3,
					CategoryId: 0,
				},
			};
		},
		mounted() {
			if (this._isMobile()) {
				window.location.href = "https://mobile2021.mims.com.cn/";
			}
		},
		computed: {
			...mapState({
				adMap: state => (state.ad.adMap[1] || {})[0] || {},
			}),
		},
		created() {
			this.$apis.app.getBanner({
				AscriptionId: 1,
				ResRefId: 0
			}).then(res => (this.banner = res.data));
			this.getAds();
		},
		methods: {
			...mapActions('ad', ['getAds']),
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)
				return flag;
			}

		},
	};
</script>

<style lang="scss">
	.main-banner {
		@include padding-height(45%);
		background: $skeleton-bg;
	}
</style>
